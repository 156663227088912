import * as React from 'react'
import { useRouter } from 'next/router'
import StoryblokService from 'utils/storyblok-service'
// eslint-disable-next-line import/no-cycle
import { media } from 'utils/transforms'
import ContentHeroComponent from './ContentHero'

export const transformProps = (propsArg) => {
  const props = { ...propsArg }

  if (props.backgroundMedia && props.backgroundMedia.filename) {
    props.backgroundMediaProps = media(props.backgroundMedia)

    delete props.backgroundMedia
  }

  if (props.content) {
    props.content = StoryblokService?.client?.richTextResolver?.render(props.content)
  }

  return props
}
const ContentHero = (props) => {
  const router = useRouter()
  let productCodes
  let dtName
  let dtGoals

  if (router.query.codes) {
    productCodes = router.query.codes
  }
  if (router.query.name) {
    dtName = router.query.name
  }
  if (router.query.goals) {
    dtGoals = router.query.goals
  }

  return (
    <ContentHeroComponent
      {...transformProps(props)}
      productCodes={productCodes}
      dtName={dtName}
      dtGoals={dtGoals}
    />
  )
}

export default ContentHero
