/* eslint-disable react/no-danger */
import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Media from '@oakwood/oui/Media'
import MediaReveal from '@oakwood/oui/MediaReveal'
import Image from 'next/image'
import { mediaType } from 'utils'
import RouterLink from 'containers/RouterLink'
import Button from 'components/Button'
import Container from 'components/Container'
import Html from 'components/Html'
import Typography from 'components/Typography'
import { useTranslations } from 'containers/Translations/TranslationsContext'
import Section from 'components/Section'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -1,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.common.black,
    minHeight: 300,
    [theme.breakpoints.up('sm')]: {
      minHeight: 400,
    },
  },
  textColorInverted: {
    color: theme.palette.common.white,
  },
  backgroundMediaMain: {
    ...theme.mixins.absolute(0),
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  backgroundMedia: {
    height: '100%',
  },
  main: {
    ...theme.mixins.verticalRhythm(3),
    // textAlign: 'center',
  },
  cta: {
    maxWidth: 390,
  },
  ctaWrap: {
    display: 'flex',
    width: '100%',
  },
  recommendationContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    minHeight: 300,
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      minHeight: 400,
    },
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '3fr 6fr',
      gap: theme.spacing(6),
      minHeight: 400,
    },
  },
  recImageContainer: {
    position: 'relative',
    display: 'block',
    minHeight: 300,
    width: '100%',
  },
  recFlowContianer: {
    display: 'flex',
    flexDirection: 'column',
  },
  recPadding: {
    padding: theme.spacing(0, 2, 4, 2),
    [theme.breakpoints.up('sm')]: { padding: theme.spacing(6, 0, 6, 4) },
  },
  textSubtitleOne: {
    ...theme.typography.subtitle1,
    fontWeight: 300,
    lineHeight: '20px',
    textTransform: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '75%',
    },
  },
  textHeadingFive: {
    ...theme.typography.h5,
  },
  textHeadingThree: {
    ...theme.typography.h3,
  },
  recommendationTitle: {
    ...theme.typography.subtitle1,
    color: '#595463',
    fontWeight: 400,
  },
  backgroundMediaContainer: {
    display: 'block',
    position: 'relative',
    height: '100%',
    width: '100%',
  },
}))

function ContentHero(props) {
  const {
    backgroundMediaProps,
    content,
    ctaLabel,
    ctaUrl,
    textColor = 'default',
    minHeight,
    padding,
    ctaType = 'contained',
    ctaButtonSize = 'medium',
    ctaButtonFullRow = false,
    txtAlign = 'center',
    ctaButtonAlign = 'center',
    productCodes,
    dtName,
    dtGoals,
    renderIndex,
  } = props
  const classes = useStyles(props)

  let cType = 'contained'
  if (ctaType.includes('outlined')) {
    cType = 'outlined'
  } else if (ctaType.includes('contained')) {
    cType = 'contained'
  } else {
    cType = ctaType
  }

  const t = useTranslations()

  const storyblokStyles = {}

  if (padding !== '') storyblokStyles.padding = padding
  if (minHeight !== '') storyblokStyles.minHeight = minHeight

  React.useEffect(() => {
    // Remove overflow hidden from body caused by DialogTrail
    if (document) {
      const dtClass = document.body.classList.contains('dt-overflow-hidden')
      if (dtClass) {
        document.body.classList.remove('dt-overflow-hidden')
        document.body.style.overflow = 'visible'
      }
    }
  }, [])

  return (
    <>
      {productCodes ? (
        <Section className={classes.recommendationContainer}>
          <div className={clsx(classes.recFlowContianer, classes.recPadding)} style={{ gap: 48 }}>
            <div className={classes.recFlowContianer} style={{ gap: 32 }}>
              <Typography variant="h1" className={clsx(classes.recommendationTitle)}>
                {t('Web.Recommendation.Title')}
              </Typography>

              <div className={classes.recFlowContianer} style={{ gap: 8 }}>
                <Typography className={classes.textHeadingFive}>
                  {t('Web.Recommendation.Selected')}
                </Typography>
                <Typography className={classes.textHeadingThree}>
                  {dtName || t('Web.Recommendation.Name')}
                </Typography>
              </div>
            </div>

            <div className={classes.recFlowContianer} style={{ gap: 24 }}>
              <Typography variant="h1" className={classes.textSubtitleOne}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('Web.Recommendation.Description').replace(
                      '{goals}',
                      `<strong>${dtGoals}</strong>` || '',
                    ),
                  }}
                />
              </Typography>
            </div>
          </div>
          <div className={classes.recImageContainer}>
            {backgroundMediaProps && backgroundMediaProps.breakpoints && (
              <Image
                src={backgroundMediaProps?.breakpoints?.xs}
                alt={'ContentHero Recommendation Image'}
                layout="fill"
                objectFit="cover"
                sizes="(max-width: 600px) 100vw, 60vw"
              />
            )}
          </div>
        </Section>
      ) : (
        <Section
          className={clsx(classes.root, {
            [classes.textColorInverted]: textColor === 'inverted',
          })}
          style={storyblokStyles}
          gutters="padding"
        >
          {backgroundMediaProps && (
            <>
              {backgroundMediaProps.component === 'video' ? (
                <MediaReveal className={classes.backgroundMediaMain}>
                  <Media className={classes.backgroundMedia} {...backgroundMediaProps} />
                </MediaReveal>
              ) : (
                <div className={classes.backgroundMediaMain}>
                  <div className={classes.backgroundMediaContainer}>
                    <Image
                      src={backgroundMediaProps?.breakpoints?.xs}
                      alt={'ContentHero Image'}
                      layout="fill"
                      objectFit="cover"
                      sizes="100vw"
                      priority={renderIndex === 0}
                    />
                  </div>
                </div>
              )}
            </>
          )}

          <Container className={classes.main} maxWidth="md">
            {content && (
              <Html style={{ textAlign: txtAlign }} dangerouslySetInnerHTML={{ __html: content }} />
            )}

            {ctaLabel && ctaUrl && (
              <div className={classes.ctaWrap} style={{ justifyContent: ctaButtonAlign }}>
                <Button
                  className={classes.cta}
                  component={RouterLink}
                  href={ctaUrl}
                  variant={cType}
                  size={ctaButtonSize}
                  fullWidth={ctaButtonFullRow}
                >
                  {ctaLabel}
                </Button>
              </div>
            )}
          </Container>
        </Section>
      )}
    </>
  )
}

ContentHero.propTypes = {
  backgroundMediaProps: mediaType,
  content: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaType: PropTypes.string,
  ctaButtonSize: PropTypes.string,
  ctaButtonFullRow: PropTypes.bool,
  textColor: PropTypes.oneOf(['default', 'inverted', '']),
  txtAlign: PropTypes.oneOf(['left', 'center', 'right', 'justify', '']),
  ctaButtonAlign: PropTypes.oneOf(['left', 'center', 'right', '']),
  productCodes: PropTypes.string,
  dtName: PropTypes.string,
  dtGoals: PropTypes.string,
  minHeight: PropTypes.string,
  padding: PropTypes.string,
  renderIndex: PropTypes.number,
}

export default ContentHero
